$mg_kufer_spinner_padding: 2.5rem;
$mg_kufer_spinner_size: 5rem;
$mg_kufer_spinner_height: $mg_kufer_spinner_padding * 2 + $mg_kufer_spinner_size;
.mg_kuferarea {
  min-height: $mg_kufer_spinner_height;
  background: $main-theme--light;
  & > .container {
    padding: 0;
    & > .row {
      padding: 0;
      margin: 0;
      & > div[class^="col-"] {
        padding: 0;
      }
    }
  }
}

.mg_kuferinclude {
  display: block;
  &::before {
    display: block;
    content: '\f110';
    font: $mg_kufer_spinner_size FontAwesome;
    color: #fff;
    text-align: center;
    -webkit-animation: fa-spin 6s infinite linear;
    animation: fa-spin 6s infinite linear;
    padding: $mg_kufer_spinner_padding 0;
  }
  #kuferinclude {
    position: relative;
    display: block;
    z-index: 10;
    margin-top: $mg_kufer_spinner_height * -1;
  }

  .hauptseite {
    &_status {
      display: none;
    }
    &_kurse {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background: #fff;
      min-height: 6rem;

      > div[class^="col-"] {
        padding-top: 4rem;
      }
    }
  }

  .kategorien_bg {
    @include mg-triangle($mg-space--xsmall, get-color($mg-theme, 'light'));
    margin-top: 0 !important;
    background-color: get-color($mg-theme, 'light');
    flex: 0 0 100%;

    & ~ div:last-of-type:not(div[class^="col-"])::after {
      content: "Bitte wählen Sie eine Kategorie, aus der oben stehenden Liste.";
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 2rem;
  }
    
    .kategorielinkbg {
      @include mg-button;
      width: calc(100% - 2em);
      margin: $mg-space--xsmall;
      display: inline-block;
      text-align: center;
      font-weight: bold;
      &.kategaktiv1 {
        background-color: get-color($mg-theme, 'dark');
      }
    }
  }

  .kw-kursheader a {
    color: get-color($mg-theme, 'dark');
  }
  .kw-button {
    background: get-color($mg-theme, 'main');
    border-color: get-color($mg-theme, 'main');
    color: #fff !important;

    &:hover {
      background: get-color($mg-theme, 'dark');
      border-color: get-color($mg-theme, 'dark');
      color:#fff;
    }
    .fa {
      color: #fff !important;
    }
  }
  .fa {
    color: get-color($mg-theme, 'main') !important;
  }

  // Pagination
  .kw-paginationleiste {
    .align-center {
      display: flex;
      flex-direction: column;
      align-items: center;    
    }
  
    .pagination > li > a,
    .pagination > li > span {
      border-color: get-color($mg-theme) !important;
      background-color: get-color($mg-theme) !important;
      color:#fff;
      font-weight: bold
    }
    .pagination > .active > a,
    .pagination > li > a:hover {
      border-color: get-color($mg-theme, 'dark') !important;
      background-color: get-color($mg-theme, 'dark') !important;
    }
  }

  // Suche
  .hauptseite_kurse > .col-md-3 form {
    input {
      border: 1px solid get-color($mg-theme, 'dark');
    }
    button {
      background-color: get-color($mg-theme) !important;
      color: #fff !important;
      border: none;
      right: 0 !important;
      top: 0 !important;
      height: 34px;
      padding: 0 0.5em;

      .fa {
        color: #fff !important;
      }

      &:hover {
        background-color: get-color($mg-theme, 'dark') !important;
        color: #fff;
      }
    }
  }

}
